import {Card} from "../../Component/Card/Card";
import {Title} from "../../Component/Title/Title";
import {Typography} from "../../Component/Typography/Typography";
import styles from "./AboutUs.module.css"

export function AboutUs() {
  return (
    <div style={{
      width: "100%",
      position: "relative",
      zIndex: 50
    }}>
      <Card style={{
        padding: "42px",
        paddingBottom: "23px",
        borderRadius: "20px"
      }}>
        <Title title="Qui sommes-nous ?" fontSize={"32px"} style={{
          color: "#ffffff",
          marginBottom: "23px"
        }} />
        <Typography fontSize="16px" color="#F8F7F7FF" style={{
          fontWeight: "100"
        }}>
          Fort de cinq ans d'expérience, notre entreprise se consacre à booster la productivité des sociétés avec l'Intelligence Articielle. Nous excelons dans la création d'assistant conversationnels (chatbots) personnalisés, l’automatisation de processus, l'analyse approfondie des données, et le développement d'outils IA sur-mesure. Notre passion pour l'innovation en IA nous amène à offrir des solutions de pointe, répondant aux défis actuels et futurs des entreprises.
        </Typography>
        <div style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "20px"
        }}>
          <span className={styles["gradient-background"]} style={{
            width: "115px",
            height: "6px",
            borderRadius: "5px",
            marginRight: "10px",
          }}></span>
          <span className={styles["gradient-background"]} style={{
            width: "12px",
            height: "6px",
            borderRadius: "5px"
          }}></span>
        </div>
      </Card>
    </div>
  )
}
import styles from './Button.module.css'
import { motion } from "framer-motion";

export function Button({title, onClick, background, color, width, height, fontSize, style}) {
  return (
    <motion.div style={{
      width: width ? width : 185,
      height: height ? height : 63,
      ...style
    }}
      whileHover={{ scale: [null, 1.15, 1.1] }}
      transition={{ duration: 0.2 }}
    >
      <button onClick={onClick} className={styles["button"]} style={{
        fontSize: fontSize ? fontSize : 16,
        width: "100%",
        height: "100%",
        color: color ? color : "#FFFFFF",
        background: background ? background : "#193453"
      }}>
        {title}
      </button>
    </motion.div>
  )
}
import {Container} from "../../Component/Container/Container";
import {Card} from "../../Component/Card/Card";
import {ReactComponent as Logo} from "./Logo.svg";
import {ReactComponent as CircleDecoration} from "../../Assets/circle-decoration.svg";
import {Title} from "../../Component/Title/Title";
import useScreenSize from "../../Hooks/useScreenSize";
import "../../Assets/rotation-animation.css"

export function Footer() {
  const screenSize = useScreenSize();

  return (
    <Container style={{
      background: "#F6F7F8",
      paddingBottom: "25px"
    }}>
      <Card background="#FFFFFF" style={{
        padding: "45px",
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        zIndex: 15,
        flexDirection: screenSize.width <= 775 ? "column" : "row"
      }}>
        <div style={{
          width: screenSize.width <= 775 ? "100%" : "60%",
        }}>
          <Logo style={{
            width: screenSize.width <= 460 ? "100%" : "auto",
            transform: "translate(-8%, -30%)"
          }} />
          <Title
            title="Libérez le potentiel de votre entreprise avec nos technologies d'automatisation avancées."
            fontSize="24px"
            style={{
              marginTop: "25px",
              color: "#474B50",
              wordWrap: "break-word"
            }}
          />
        </div>
        <div style={{
          display: "flex",
          alignItems: screenSize.width >= 775 ? "flex-end" : "center",
          flexDirection: "column",
          marginTop: screenSize.width >= 775 ? "0" : "25px",
          height: "100%",
          justifyContent: "space-between",
        }}>
          <div>
            <Title
              title="hello@zendai.fr"
              fontSize="24px"
              style={{
                color: "#193453",
                textDecoration: "underline"
              }}
            />
            <Title
              title="+33 06 25 14 54"
              fontSize="24px"
              style={{
                color: "#193453",
                marginTop: "15px",
                marginBottom: "35px"
              }}
            />
          </div>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/2048px-LinkedIn_icon.svg.png"
            alt="Linkedin"
            width="57px"
            height="57px"
            style={{
              cursor: "pointer"
            }}
          />
        </div>
      </Card>
      <Title title="©2024 Zendai.ai" fontSize="16px" style={{
        color: "#787D84",
        marginTop: "10px",
        textAlign: "center"
      }}/>
      <div style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        transform: "translate(50%, 50%)"
      }}>
        <CircleDecoration className="rotating" />
      </div>
    </Container>
  )
}
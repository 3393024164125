import {CategoryTitle} from "../../Component/CategoryTitle/CategoryTitle";
import {Card} from "../../Component/Card/Card";
import {ReactComponent as IconA} from "./IconA.svg";
import {ReactComponent as IconB} from "./IconB.svg";
import {ReactComponent as IconC} from "./IconC.svg";
import {Title} from "../../Component/Title/Title";
import {Typography} from "../../Component/Typography/Typography";
import useScreenSize from "../../Hooks/useScreenSize";

export function Services() {
  const screenSize = useScreenSize();

  return (
    <>
      <CategoryTitle
        title="Nous vous aidons à identifier, explorer et répondre aux nouvelles opportunités."
        subtitle="Nos services"
        description=""
        style={{
          marginTop: "62px"
        }}
        id="Services"
      />
      <div style={{
        marginTop: "45px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        textAlign: "center",
      }}>
        <Card background="#F6F7F8" style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          width: screenSize.width >= 1250 ? "30%" : (screenSize.width >= 915 ? "45%" : "100%"),
          marginBottom: "30px"
        }}>
          <IconA></IconA>
          <Title title="Conseil AI" fontSize="36px" style={{color: "#000000", marginTop: "20px", marginBottom: "20px"}} />
          <Typography>
            Analyse personnalisée de vos processus pour trouver des opportunités d'optimisation et automatisation par l'IA
          </Typography>
        </Card>
        <Card background="#F6F7F8" style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          width: screenSize.width >= 1250 ? "30%" : (screenSize.width >= 915 ? "45%" : "100%"),
          marginBottom: "30px"
        }}>
          <IconB></IconB>
          <Title title="Formation AI" fontSize="36px" style={{color: "#000000", marginTop: "20px", marginBottom: "20px"}} />
          <Typography>
            Nous proposons des workshops pour vos équipes pour exploiter efficacement l'IA et maintenir votre entreprise à la pointe.
          </Typography>
        </Card>
        <Card background="#F6F7F8" style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          width: screenSize.width >= 1250 ? "30%" : (screenSize.width >= 915 ? "45%" : "100%"),
          marginBottom: "30px"
        }}>
          <IconC></IconC>
          <Title title="Prestation AI" fontSize="36px" style={{color: "#000000", marginTop: "20px", marginBottom: "20px"}} />
          <Typography>
            Nous offrons des solutions d'IA sur mesure adaptés à vos besoin tel que des agents conversationnel avec vos documents, de la prospection automatisé, …
          </Typography>
        </Card>
      </div>
    </>
  )
}
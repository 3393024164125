export function Typography({children, fontSize, color, style}) {
  return (
    <p style={{
      fontSize: fontSize ? fontSize : 16,
      color: color ? color : "#474B50",
      ...style
    }}>
      {children}
    </p>
  )
}
import styles from "./Header.module.css"
import "../../Assets/rotation-animation.css"
import {Button} from "../../Component/Button/Button";
import {ReactComponent as CircleDecoration} from "../../Assets/circle-decoration.svg";
import useScreenSize from "../../Hooks/useScreenSize";

export function Header() {
  const screenSize = useScreenSize();

  return (
    <>
      <div style={{
        width: "100%",
        height: "calc(100vh - 83px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
      }}>
        <div style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          width: screenSize.width >= 1250 ? "50%" : "100%",
          alignItems: screenSize.width >= 1250 ? "flex-start" : "center",
          zIndex: 50,
          position: "relative"
        }}>
          <h1 style={{
            fontWeight: "bold",
            color: "#193453",
            fontSize: screenSize.width >= 500 ? "48px" : "32px",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            marginBottom: "25px",
            textAlign: screenSize.width >= 1250 ? "left" : "center"
          }}>
            Automatisez vos processus et augmentez votre productivité avec
            <span className={styles["gradient-text"]}>
            l'intelligence artificielle
          </span>
          </h1>
          <Button title="Contactez-nous" onClick={() => {
            const element = document.getElementById("Contact")

            window.scrollTo({
              top: element.getBoundingClientRect().top,
              behavior: 'smooth'
            });
          }}></Button>
        </div>
      </div>
      <div style={{
        position: "absolute",
        top: screenSize.width >= 1250 ? "50vh" : "0vh",
        right: 0,
        zIndex: -50,
        transform: "translate(50%, -50%)"
      }}>
        <CircleDecoration width="100%" className="rotating" />
      </div>
      <div style={{
        position: "absolute",
        top: "100vh",
        left: 0,
        transform: "translate(-50%, -50%)"
      }}>
        <CircleDecoration width="100%" className="rotating" />
      </div>
    </>
  )
}
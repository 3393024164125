import { motion } from "framer-motion";

export function Card({children, background, className, style}) {
  return (
    <motion.div
      className={className}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={{
        offscreen: {
          y: 100
        },
        onscreen: {
          y: 0,
          transition: {
            type: "spring",
            bounce: 0.3,
            duration: 0.8
          }
        }
      }}
      style={{
        background: background ? background : "#193453",
        borderRadius: "20px",
        padding: 42,
        ...style
      }}
    >
      {children}
    </motion.div>
  )
}
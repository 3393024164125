import {CategoryTitle} from "../../Component/CategoryTitle/CategoryTitle";
import {Container} from "../../Component/Container/Container";
import HuggingFace from "./logos/hugging face.png";
import NvidiaCuda from "./logos/NVIDIA_CUDA.jpg";
import Pytorch from "./logos/pytorch.png";
import TensorFlow from "./logos/TensorFlow.png";
import useScreenSize from "../../Hooks/useScreenSize";

export function Technologies() {
  const screenSize = useScreenSize();

  return (
    <Container style={{
      marginTop: "81px"
    }}>
      <CategoryTitle
        title="Les outils qui alimentent nos solutions IA"
        subtitle="Au Cœur de l'Innovation :"
        description=""
        style={{
          marginTop: "62px"
        }}
      />
      <div style={{
        marginTop: "45px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        textAlign: "center",
      }}>
        <div style={{
          width: screenSize.width >= 1250 ? "23%" : "45%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img style={{
            maxWidth: "100%",
          }} src={Pytorch} alt="Pytorch"/>
        </div>
        <div style={{
          width: screenSize.width >= 1250 ? "23%" : "45%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img style={{
            maxWidth: "100%",
          }} src={TensorFlow} alt="TensorFlow"/>
        </div>
        <div style={{
          width: screenSize.width >= 1250 ? "23%" : "45%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img style={{
            maxWidth: "100%",
          }} src={NvidiaCuda} alt="NvidiaCuda"/>
        </div>
        <div style={{
          width: screenSize.width >= 1250 ? "23%" : "45%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <img style={{
            maxWidth: "100%",
          }} src={HuggingFace} alt="HuggingFace"/>
        </div>
      </div>
    </Container>
)
}
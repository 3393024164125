export function Title({title, fontSize, style}) {
  return (
    <div style={{
      width: "100%",
    }}>
      <h1 style={{
        fontSize: fontSize ? fontSize : 36,
        color: "#193453",
        fontWeight: "500",
        ...style
      }}>
        {title}
      </h1>
    </div>
  )
}
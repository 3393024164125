export function TextArea({placeholder, value, onChange, height, style}) {
  return (
    <textarea placeholder={placeholder} value={value} onChange={onChange}
    style={{
      width: "100%",
      border: "none",
      padding: "15px",
      color: "#474B50",
      fontSize: "16px",
      fontWeight: "500",
      borderRadius: "5px",
      background: "#FFFFFF",
      height: height,
      resize: "none",
      outline: "none",
      ...style
    }}></textarea>
  )
}
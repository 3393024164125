import {Title} from "../Title/Title";
import useScreenSize from "../../Hooks/useScreenSize";

export function CategoryTitle({title, subtitle, description, style, id}) {
  const screenSize = useScreenSize();

  return (
    <div style={{
      width: screenSize.width >= 1050 ? "50%" : "100%",
      ...style
    }} id={id}>
      <h3 style={{
        fontSize: 20,
        fontWeight: "bold",
        color: "#787D84"
      }}>
        {subtitle}
      </h3>
      <Title title={title} style={{
        fontWeight: "600"
      }}></Title>
      <h3 style={{
        fontSize: 18,
        fontWeight: "bold",
        color: "#787D84"
      }}>
        {description}
      </h3>
    </div>
  )
}
import {Card} from "../../Component/Card/Card";
import {Title} from "../../Component/Title/Title";
import {Typography} from "../../Component/Typography/Typography";
import styles from "./Presentation.module.css"
import {Container} from "../../Component/Container/Container";

export function Presentation() {
  return (
    <Container style={{
      marginTop: "81px",
      marginBottom: "81px"
    }}>

      <div style={{
        width: "100%",
        position: "relative",
        zIndex: 50
      }}>
        <Card style={{
          padding: "42px",
          paddingBottom: "23px",
          borderRadius: "20px"
        }}>
          <Title title="L'entreprise" fontSize={"32px"} style={{
            color: "#ffffff",
            marginBottom: "23px"
          }} />
          <Typography fontSize="16px" color="#F8F7F7FF" style={{
            fontWeight: "100"
          }}>
            Zenkai signifie plein puissance en japonais.
            <br/><br/>
            Notre entreprise est née de la vision d'augmenter la productivité de nos clients grâce à l'automatisation
            intelligente et la formation de vos équipes. Chaque entreprise doit prendre le virage de l’intelligence
            artificielle afin de maximiser son potentiel en optimisant ses processus et en libérant ses ressources pour
            se concentrer sur ce qui compte vraiment.
            <br/><br/>
            Nous adoptons une approche consultative, très réactive, sur-mesure et pédagogique pour comprendre les
            besoins uniques de chaque client et proposer des solutions personnalisées.
            <br/><br/>
            <bold style={{fontWeight: "500", fontSize: "18px"}}>Notre équipe</bold>
            <br/><br/>
            Notre équipe est composée d'experts passionnés par l'intelligence artificielle de pointe et la programmation
            informatique dévoués à la réussite de nos clients. Avec une expertise approfondie dans une variété de
            domaines (le traitement du langage naturel, la vision par ordinateur, machine learning…), nous sommes prêts
            à relever les défis les plus complexes et à fournir d'excellents résultats.
          </Typography>
          <div style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "20px"
          }}>
            <span className={styles["gradient-background"]} style={{
              width: "115px",
              height: "6px",
              borderRadius: "5px",
              marginRight: "10px",
            }}></span>
            <span className={styles["gradient-background"]} style={{
              width: "12px",
              height: "6px",
              borderRadius: "5px"
            }}></span>
          </div>
        </Card>
      </div>
    </Container>
  )
}
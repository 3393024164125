export function Input({placeholder, value, onChange, style}) {
  return (
    <input
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      style={{
        width: "100%",
        border: "none",
        padding: "15px",
        color: "#474B50",
        fontSize: "16px",
        fontWeight: "500",
        borderRadius: "5px",
        background: "#FFFFFF",
        height: "50px",
        outline: "none",
        ...style
      }}
    />
  )
}
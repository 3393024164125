import {CategoryTitle} from "../../Component/CategoryTitle/CategoryTitle";
import {Container} from "../../Component/Container/Container";
import {Input} from "../../Component/Input/Input";
import {TextArea} from "../../Component/TextArea/TextArea";
import {Button} from "../../Component/Button/Button";
import useScreenSize from "../../Hooks/useScreenSize";

export function Contact() {
  const screenSize = useScreenSize();

  return (
    <Container style={{
      background: "#F6F7F8",
      paddingBottom: "85px"
    }}>
      <CategoryTitle
        title="Une question ? Contactez-nous !"
        subtitle="Contact"
        description="Remplissez le formulaire et nous revenons vers vous sous 24 heures."
        style={{
          paddingTop: "55px",
          marginBottom: "50px"
        }}
        id="Contact"
      />
      <Input placeholder="Votre Nom" style={{
        marginBottom: "35px"
      }} />
      <Input placeholder="Votre Email" style={{
        marginBottom: "35px"
      }} />
      <TextArea placeholder="Votre Message" height="249px" style={{
        marginBottom: "50px"
      }} />
      <Button title="Envoyer ma demande" width={screenSize.width >= 1250 ? "25%" : "100%"} background="#FF864A" color="#193453" />
    </Container>
  )
}
import {Container} from "../../Component/Container/Container";
import {CategoryTitle} from "../../Component/CategoryTitle/CategoryTitle";
import {ReactComponent as Arrow} from "./arrow.svg";
import {Card} from "../../Component/Card/Card";
import {Typography} from "../../Component/Typography/Typography";
import useScreenSize from "../../Hooks/useScreenSize";

export function Advice() {
  const screenSize = useScreenSize();

  return (
    <Container style={{
      marginTop: "81px",
      paddingBottom: "81px"
    }}>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "35px",
        flexDirection: screenSize.width <= 1000 ? "column" : "row"
      }}>
        <CategoryTitle
          title="Que disent nos clients à notre sujet ?"
          subtitle="Nos avis"
          description="La satisfaction de nos clients est notre priorité absolue. Nous nous efforçons de dépasser les attentes à chaque étape du parcours client et de fournir un soutien continu pour garantir le succès à long terme de nos clients."
        />
        <div style={{
          display: "none",
          alignItems: "center",
          justifyContent: "space-between",
          width: "60px",
          marginTop: "25px"
        }}>
          <div style={{
            cursor: "pointer"
          }}>
            <Arrow/>
          </div>
          <div style={{
            cursor: "pointer"
          }}>
            <Arrow style={{
              transform: "rotate(180deg)"
            }} />
          </div>
        </div>
      </div>
      <Card background="#F6F7F8" style={{
        width: "100%",
        padding: "40px"
      }}>
        <Typography fontSize="16px">
          It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here
        </Typography>
        <div style={{
          display: "flex",
          alignItems: "center",
          marginTop: "25px"
        }}>
          <img
            src="https://images.assetsdelivery.com/compings_v2/gmast3r/gmast3r1411/gmast3r141100350.jpg"
            alt="profile"
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "60px",
              marginRight: "15px"
            }}
          />
          <div style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column"
          }}>
            <h1 style={{
              fontWeight: "500",
              color: "#000000",
              fontSize: "20px"
            }}>Prestation AI</h1>
            <h4 style={{
              fontWeight: "500",
              color: "#474B50",
              fontSize: "16px"
            }}>CEO, Energie</h4>
          </div>
        </div>
      </Card>
    </Container>
  )
}
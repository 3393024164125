import {ReactComponent as Logo} from './logo.svg'
import {Button} from "../../Component/Button/Button";
import useScreenSize from "../../Hooks/useScreenSize";
import {useState} from "react";

export function Navbar() {
  const screenSize = useScreenSize();
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      height: "63px",
      paddingTop: "20px"
    }}>
      <Logo />
      {
        screenSize.width >= 1250 ?
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <Button title="Nos services" background="#FFFFFF" color="#193453" style={{marginRight: "10px"}}
            onClick={(e) => {
              e.preventDefault()
              const element = document.getElementById("Services")

              window.scrollTo({
                top: element.getBoundingClientRect().top,
                behavior: 'smooth'
              });
            }}></Button>
            <Button title="Contactez-nous" onClick={() => {
              const element = document.getElementById("Contact")

              window.scrollTo({
                top: element.getBoundingClientRect().top,
                behavior: 'smooth'
              });
            }}></Button>
          </div>
          :
            <>
              <div style={{
                display: "flex",
                alignItems: isOpen ? "center" : "flex-end",
                flexDirection: "column",
                justifyContent: isOpen ? "center" : "space-between",
                width: "30px",
                height: "21px",
                position: isOpen ? "fixed" : "unset",
                top: "30px",
                right: "13%",
                zIndex: 150
              }}
              onClick={() => {
                setIsOpen(isOpen => !isOpen)
              }}>
                <div style={{
                  position: isOpen ? "absolute" : "relative",
                  background: "#193453",
                  borderRadius: "2px",
                  width: "100%",
                  height: "4px",
                  transform: isOpen ? "rotate(-45deg)" : "rotate(0deg)",
                  transformOrigin: "center",
                  transition: "all 0.2s ease-out"
                }}/>
                <div style={{
                  position: isOpen ? "absolute" : "relative",
                  background: "#193453",
                  borderRadius: "2px",
                  width: "80%",
                  height: "4px",
                  display: isOpen ? "none" : "unset",
                  transition: "all 0.2s ease-out"
                }}/>
                <div style={{
                  position: isOpen ? "absolute" : "relative",
                  background: "#193453",
                  borderRadius: "2px",
                  width: "100%",
                  height: "4px",
                  transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
                  transformOrigin: "center",
                  transition: "all 0.2s ease-out"
                }}/>
              </div>
              <div style={{
                position: "fixed",
                width: "100%",
                height: screenSize.height + "px",
                top: 0,
                right: isOpen ? "0%" : "-100%",
                zIndex: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                transition: "all 0.2s ease-out"
              }}>
                <div style={{
                  width: "20%",
                  height: "100%"
                }}
                     onClick={() => {
                       setIsOpen(isOpen => !isOpen)
                     }}
                />
                <div style={{
                  background: "#F6F7F8",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  width: "80%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  padding: "25px",
                  paddingTop: "85px"
                }}>
                  <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "column"
                  }}>
                    <Button title="Nos services" background="#F6F7F8" color="#193453" style={{marginRight: "10px"}}
                            onClick={() => {
                              const element = document.getElementById("Services")

                              window.scrollTo({
                                top: element.getBoundingClientRect().top,
                                behavior: 'smooth'
                              });
                              setIsOpen(false)
                            }}></Button>
                  </div>
                  <Button title="Contactez-nous" onClick={() => {
                    const element = document.getElementById("Contact")

                    window.scrollTo({
                      top: element.getBoundingClientRect().top,
                      behavior: 'smooth'
                    });
                    setIsOpen(false)
                  }}></Button>
                </div>
              </div>
            </>
      }
    </div>
  )
}
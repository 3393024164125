import {Container} from "../../Component/Container/Container";
import {CategoryTitle} from "../../Component/CategoryTitle/CategoryTitle";
import {Card} from "../../Component/Card/Card";
import {ReactComponent as Line} from "./Line.svg";
import {ReactComponent as IconLightBulb} from "./Icons/lightbulb-on.svg";
import {ReactComponent as Refresh} from "./Icons/refresh.svg";
import {ReactComponent as Satellite} from "./Icons/satellite.svg";
import {ReactComponent as Search} from "./Icons/search.svg";
import {ReactComponent as Users} from "./Icons/users-alt.svg";
import {Title} from "../../Component/Title/Title";
import {Typography} from "../../Component/Typography/Typography";
import useScreenSize from "../../Hooks/useScreenSize";

function ProcessCard({icon, number, title, description, style}) {
  const screenSize = useScreenSize();

  return (
    <Card
      background="#FFFFFF"
      style={{
      width: screenSize.width >= 650 ? "calc(50% - 48px)" : "100%",
      padding: "18px",
      ...style
    }}>
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "35px"
      }}>
        <div style={{
          width: "60px",
          height: "60px",
          borderRadius: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#FD9A97"
        }}>
          {icon}
        </div>
        <h1 style={{
          fontSize: "32px",
          color: "#474B50",
          fontWeight: "500"
        }}>
          {number}
        </h1>
      </div>
      <Title title={title} fontSize="24px" style={{color: "#193453"}} />
      <Typography color="#7A7D82" fontSize="20px" style={{
        marginTop: "26px"
      }}>
        {description}
      </Typography>
    </Card>
  )
}

export function ProcessPrestation() {
  const screenSize = useScreenSize();

  return (
    <Container style={{
      background: "#F6F7F8",
      marginTop: "97px",
      paddingBottom: "69px"
    }}>
      <CategoryTitle
        title="Orchestrer votre révolution numérique à travers l'intelligence artificielle"
        subtitle="Notre Approche :"
        style={{
          paddingTop: "37px",
          marginBottom: "107px"
        }}
      />
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: screenSize.width <= 650 ? "wrap" : "unset"
      }}>
        <ProcessCard
          title="Diagnostic Préliminaire"
          description="Comprendre votre métier et les tâches que vous pourrez automatiser."
          icon={<Search/>}
          number="01"
        />
        <div style={
          screenSize.width >= 650 ?
          {
            width: "97px",
            transform: "translateX(-10px)"
          } :
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "17%",
              height: "98px"
            }
        }>
          <div style={screenSize.width <= 650 ? {
            transform: "rotate(90deg) translateX(-5px)"
          } : {}}>
            <Line/>
          </div>
        </div>
        <ProcessCard
          title="Conception de la Solution"
          description="Élaboration collaborative de prototypes d'outils d'IA innovants et alignés à vos besoins spécifiques."
          icon={<IconLightBulb/>}
          number="02"
        />
      </div>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
        paddingRight: "17%",
        height: "98px",
      }}>
        <div style={{
          transform: "rotate(90deg) translateX(-5px)"
        }}>
          <Line/>
        </div>
      </div>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: screenSize.width <= 650 ? "wrap" : "unset",
        flexDirection: screenSize.width <= 650 ? "column-reverse" : "row"
      }}>
        <ProcessCard
          title="Développement Agile"
          description="Méthodologies agiles pour le développement pour garantir la flexibilité, l’adaptation des solutions en cours de route et assurer une livraison dans les délais."
          icon={<Refresh/>}
          number="04"
        />
        <div style={
          screenSize.width >= 650 ?
            {
              width: "97px",
              transform: "translateX(10px) rotate(180deg)"
            } :
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "17%",
              height: "98px"
            }
        }>
          <div style={screenSize.width <= 650 ? {
            transform: "rotate(90deg) translateX(-5px)"
          } : {}}>
            <Line/>
          </div>
        </div>
        <ProcessCard
          title="Formation des équipes"
          description="Nous proposons des formations sur mesure pour garantir que vos collaborateurs soient à l'aise et capables d’exploiter les solutions au maximum de leur potentiel."
          icon={<Users/>}
          number="03"
        />
      </div>
      <div style={
        screenSize.width >= 650 ?
        {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        paddingLeft: "17%",
        height: "98px"
      } :
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            paddingRight: "17%",
            height: "98px"
          }
      }>
        <div style={{
          transform: "rotate(90deg) translateX(-5px)"
        }}>
          <Line/>
        </div>
      </div>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: screenSize.width <= 650 ? "wrap" : "unset"
      }}>
        <ProcessCard
          title="Suivi et veille technologique"
          description="Nous restons à vos côtés pour surveiller les performances, recueillir les retours et optimiser les systèmes et faire de la veille technologique à votre place."
          icon={<Satellite/>}
          number="05"
        />
      </div>
    </Container>
)
}
import useScreenSize from "../../Hooks/useScreenSize";
import {CategoryTitle} from "../../Component/CategoryTitle/CategoryTitle";
import {Card} from "../../Component/Card/Card";
import {Title} from "../../Component/Title/Title";
import {Typography} from "../../Component/Typography/Typography";
import {Container} from "../../Component/Container/Container";
import {useEffect, useRef, useState} from "react";
import {animate, useInView, motion } from "framer-motion";

function Counter({ from, to, prefix = "", suffix = "", fixed = 0, duration = 1}) {
  const [value, setValue] = useState("");
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    let controls;

    if (isInView) {
      controls = animate(from, to, {
        duration: duration,
        onUpdate(value) {
          const formattedValue = value.toFixed(fixed).replace(/\B(?=(\d{3})+(?!\d))/g, "'");
          setValue(prefix + formattedValue + suffix);
        },
      });
    }

    return () => controls?.stop();
  }, [isInView, from, to, prefix, suffix, fixed, duration]);

  return(
    <motion.div ref={ref}>
      <Title title={value} fontSize="45px" style={{color: "#000000", marginTop: "20px", marginBottom: "20px"}} />
    </motion.div>
  )
}

export function Statistics() {
  const screenSize = useScreenSize();

  return (
    <Container style={{
      marginTop: "81px"
    }}>
      <CategoryTitle
        title="L'impact transformateur de l'IA dans le monde des affaires"
        subtitle="En chiffres :"
        description=""
        style={{
          marginTop: "62px"
        }}
      />
      <div style={{
        marginTop: "45px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        textAlign: "center",
      }}>
        <Card background="#F6F7F8" style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          width: screenSize.width >= 1250 ? "22%" : (screenSize.width >= 915 ? "45%" : "100%"),
          marginBottom: "30px"
        }}>
          <Counter from={38} to={83} suffix="%" duration={1.5} />
          <Typography>
            des entreprises considèrent désormais l'IA comme une priorité stratégique pour l'avenir.
          </Typography>
          <a style={{
            width: "100%",
            fontSize: "14px",
            marginTop: "5px",
            textAlign: "end",
            color: "rgb(3,12,23)"
          }} href="https://lesmakers.fr/statistiques-intelligence-artificielle/">Source</a>
        </Card>
        <Card background="#F6F7F8" style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          width: screenSize.width >= 1250 ? "22%" : (screenSize.width >= 915 ? "45%" : "100%"),
          marginBottom: "30px"
        }}>
          <Counter from={25} to={45} suffix="%"/>
          <Typography>
            des entreprises ayant adopté l'IA observent une amélioration significative de la productivité du travail.
          </Typography>
          <a style={{
            width: "100%",
            fontSize: "14px",
            marginTop: "5px",
            textAlign: "end",
            color: "rgb(3,12,23)"
          }} href="https://lesmakers.fr/statistiques-intelligence-artificielle/">Source</a>
        </Card>
        <Card background="#F6F7F8" style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          width: screenSize.width >= 1250 ? "22%" : (screenSize.width >= 915 ? "45%" : "100%"),
          marginBottom: "30px"
        }}>
          <Counter from={20} to={90} suffix="%" duration={1.75}/>
          <Typography>
            des entreprises estiment que l'intelligence artificielle leur donne un avantage compétitif sur le marché.
          </Typography>
          <a style={{
            width: "100%",
            fontSize: "14px",
            marginTop: "5px",
            textAlign: "end",
            color: "rgb(3,12,23)"
          }} href="https://lesmakers.fr/statistiques-intelligence-artificielle/">Source</a>
        </Card>
        <Card background="#F6F7F8" style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          width: screenSize.width >= 1250 ? "22%" : (screenSize.width >= 915 ? "45%" : "100%"),
          marginBottom: "30px"
        }}>
          <Counter from={45000} to={600000} duration={2}/>
          <Typography>
            entreprises utilisent ChatGPT (contre 150 000 en janvier).
          </Typography>
          <a style={{
            width: "100%",
            fontSize: "14px",
            marginTop: "5px",
            textAlign: "end",
            color: "rgb(3,12,23)"
          }} href="https://siecledigital.fr/2024/04/05/loffre-chatgpt-enterprise-connait-une-croissance-fulgurante-malgre-la-concurrence/">Source</a>
        </Card>
      </div>
    </Container>
  )
}
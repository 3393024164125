import {Container} from "./Component/Container/Container";
import {Navbar} from "./Categories/Navbar/Navbar";
import {Header} from "./Categories/Header/Header";
import {AboutUs} from "./Categories/AboutUs/AboutUs";
import {Services} from "./Categories/Services/Services";
import {ProcessPrestation} from "./Categories/ProcessPrestation/ProcessPrestation";
import {Advice} from "./Categories/Advice/Advice";
import {Contact} from "./Categories/Contact/Contact";
import {Footer} from "./Categories/Footer/Footer";
import {Statistics} from "./Categories/Statistics/Statistics";
import {Technologies} from "./Categories/Technologies/Technologies";
import {Presentation} from "./Categories/Presentation/Presentation";

function App() {
  return (
    <>
      <Container>
        <Navbar></Navbar>
        <Header></Header>
        <AboutUs></AboutUs>
        <Services></Services>
      </Container>
      <ProcessPrestation/>
      <Statistics />
      <Technologies />
      <Advice />
      <Presentation />
      <Contact />
      <Footer />
    </>
  );
}

export default App;

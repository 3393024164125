export function Container({children, spacing, style}) {
  return (
    <div style={{
      paddingLeft: spacing ? spacing : "13%",
      paddingRight: spacing ? spacing : "13%",
      position: "relative",
      ...style
    }}>
      {children}
    </div>
  )
}